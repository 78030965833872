@mixin btn-color($color, $background-color, $border-color: $background-color) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;

  &:hover,
  &:focus,
  &:active {
    color: $background-color;
    background-color: $color;
    border-color: $border-color;
  }
}

@mixin font(
  $color,
  $font-size,
  $font-weight: 400,
  $font-family: $font-primary
) {
  color: $color;
  font-size: $font-size;
  font-family: $font-family;
  font-weight: $font-weight;
  text-transform: none;
}
