html,
body {
  overflow-x: hidden;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: white;
  color: $clr-dark;
  font-family: $font-primary;
  font-size: 20px;
}

a:hover,
a:focus {
  color: $clr-primary;
}

hr {
  border-top: 1px solid rgba($clr-dark-accent, 0.25);
}

section {
  padding: 75px 0;
}

@media only screen and (min-width: 768px) {
  section {
    padding: 100px 0;
  }

  section#social,
  section#shipping {
    padding: 75px 0;
  }

  section#social ~ section#contact {
    margin-top: 16px;
  }

  section#available-kittens {
    margin-top: -50px;
  }
}

.carousel-control-prev > div,
.carousel-control-next > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: black;
  border-radius: 50%;
}
