.nav-background {
  height: 0;
}

@media (min-width: 768px) {
  .nav-background {
    height: 66px;
  }
}

@media (min-width: 992px) {
  .nav-background {
    height: 75px;
  }
}
