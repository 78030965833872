#past-litters .litter-title {
  display: inline-block;
}

#past-litters .litter-section {
  padding-top: 75px;
}

#past-litters .section-heading.below-hr + .section-body {
  margin-bottom: -50px;
}

#past-litters a .litter-title {
  border: 1px solid $clr-primary;
  transition: all 0.15s;

  &:hover {
    color: $clr-primary;
    background-color: white;
  }
}
