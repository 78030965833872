.navbar-default {
  background-color: white;

  & .nav-container {
    padding: 0;
  }

  & .navbar-toggle {
    border-color: $clr-primary;
    background-color: $clr-primary;

    &:hover,
    &:focus {
      background-color: $clr-primary;
    }

    & .icon-bar {
      background-color: white;
    }
  }

  & .navbar-collapse {
    border-color: rgba(0, 0, 0, 0.25);
  }

  & .navbar-brand {
    color: $clr-dark;
    font-family: $font-logo;
    font-size: 30px;
    padding: 0;
    margin-left: 30px;
    line-height: 60px;

    &:hover,
    &:active {
      color: $clr-dark;
    }
  }

  & .nav li a {
    color: $clr-dark;
    font-family: $font-primary;
    font-size: 24px;
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:hover,
    &:focus {
      color: white;
      background-color: $clr-secondary;
    }
  }

  & .nav li.active a {
    background-color: $clr-primary;

    &:hover,
    &:focus {
      background-color: $clr-secondary;
    }
  }
}

// Media queries.
@media only screen and (min-width: 576px) {
  .navbar-default .nav li a {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-default {
    padding: 5px 0 0;
    border: 0;
    background-color: transparent;
    transition: background-color 0.15s;

    // Center nav.
    & .navbar-collapse {
      text-align: center;
    }
    & .navbar-nav {
      float: none !important;
      display: inline-block;
    }
    // End center nav.

    // Occupy the entire width of the page.
    & .nav-container {
      min-width: 100%;
    }

    & .navbar-brand {
      display: none;
    }

    & .nav li a {
      font-size: 18px;
      padding: 20px 5px;
      letter-spacing: 0px;

      transition: all 0.15s;

      &:hover,
      &:focus {
        color: $clr-secondary;
        background-color: transparent;
      }
    }

    & .nav li.active a {
      color: $clr-primary;
      background-color: transparent;
      font-weight: 700;

      &:hover,
      &:focus {
        color: $clr-secondary;
        background-color: transparent;
      }
    }

    &.navbar-shrink {
      // Fallback for browsers that don't support the rgba() function.
      background-color: $clr-navbar-bg;
      background-color: rgba($clr-navbar-bg, 0.9);

      & .nav li a {
        color: white;

        &:hover {
          color: white;
          background-color: $clr-secondary;
        }
      }

      & .nav li.active a {
        background-color: transparent;

        &:hover,
        &:focus {
          background-color: $clr-secondary;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .navbar-default .nav li a {
    font-size: 21px;
    padding: 20px 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .navbar-default {
    & .navbar-brand {
      display: inline-block;
      font-size: 34px;
      line-height: 60px;
      margin: 0 0 0 32px;
      padding: 0;
      transition: color 0.15s;
    }

    &.navbar-shrink .navbar-brand {
      color: white;
    }

    & .nav li a {
      font-size: 23px;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .navbar-default .nav li a {
    font-size: 24px;
  }
}
