$clr-primary: #4b0084;
$clr-secondary: #7300ca;
$clr-dark-accent: #767676;
$clr-dark: #222222;
$clr-navbar-bg: #4b0084;

$font-primary: "Lato", sans-serif;
$font-logo: "Kaushan Script", cursive;
$font-secondary: "Merriweather", serif;

// Functional classes.
@import 'base/reset';
@import 'base/mixins';
@import 'base/functional';

// Components.
@import 'components/photo-grid';
@import 'components/buttons';

// Layout.
@import 'layout/contact';
@import 'layout/fur-ever-homes';
@import 'layout/hero-section';
@import 'layout/kitten-deposit';
@import 'layout/nav-background';
@import 'layout/nav';
@import 'layout/past-litters';
@import 'layout/social';
