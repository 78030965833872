.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hr-dark {
  // Fallback for browsers that don't support #rrggbbaa hex color notation.
  border-top: 1px solid #777777;
  // Hex code 80 indicates an alpha value of 50%.
  border-top: 1px solid #77777780;
  display: none;
}

@media only screen and (min-width: 768px) {
  .hr-dark {
    display: block;
    margin-top: -100px;
  }
}

.btn-default {
  @include btn-color(white, $clr-primary);
}

.btn-default-bg-light-gray {
  @include btn-color(#f7f7f7, $clr-primary);
}

.section-heading {
  @include font($clr-primary, 48px, 700, $font-secondary);
  margin-top: 0;
  margin-bottom: 25px;

  &.below-hr {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) {
  .section-heading.below-hr {
    padding-top: 50px;
  }
}

.section-subheading {
  @include font($clr-primary, 36px, 400, $font-secondary);
  margin-top: 0;
  margin-bottom: 25px;
}

.section-body {
  @include font($clr-dark, 20px);
}

.litter-title {
  @include font(white, 22px, 300, $font-secondary);
  background-color: $clr-primary;
  padding: 18px 28px;
  border-radius: 50rem;
  display: inline-block;
  font-style: italic;
}

.title {
  @include font($clr-dark, 32px, 700);
}

.caption {
  @include font($clr-dark-accent, 22px);
  line-height: 1.5;
}

.family-caption {
  @include font($clr-dark, 22px);
  line-height: 1.5;
}

.carousel .carousel-caption {
  position: absolute;
  bottom: 0;
  width: fit-content;
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);

  > h3 {
    font-size: 20px;
    padding: 12px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-radius: 10px;
  }
}

// Default image sizes.
.img-fluid {
  max-height: 600px;
}

.carousel .img-fluid {
  min-height: 450px;
}

// Image styles.
.border-box-shadow {
  border: 1px solid $clr-dark-accent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

// Spacing classes not in Bootstrap.
.pt-6,
.py-6 {
  padding-top: 6rem !important;
}
.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.mt-6,
.my-6 {
  margin-top: 6rem !important;
}
.mb-6,
.my-6 {
  margin-bottom: 6rem !important;
}

@media (min-width: 992px) {
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important;
  }
}
